<template>
    <div class="col-12 vh-100">
        <div class="row">
            <div v-if="!showloadermodal">
                <div class="d-flex align-items-end flex-column vh-100 p-0 conversation">
                    <div class="chatbot-header-outer-padding">
                        <div class="chatbot-header-inner-bg">
                            <div class="whatsapp-header">
                                <h5 class="modal-title" id="staticBackdropLabel">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-shrink-0">
                                            <img v-if="this.logoname != null && this.logoname != ''"
                                                :src="this.clientlogo" class="profile-header-avatar rounded-circle"
                                                alt="" width="46" height="46" />
                                            <img v-else src="/assets/images/login-icon.png"
                                                class="profile-header-avatar rounded-circle" alt="" width="46"
                                                height="46" />
                                        </div>
                                        <div class="flex-grow-1" style="margin-left: 15px;">
                                            <div class="whatsapp-header-name text-capitalize">{{ this.clientname ?
                                                this.clientname :
                                                "" }}<img
                                                    src="/assets/images/whatsapp-icon/material-symbols_verified-rounded.png"
                                                    class="ms-1" alt="" width="17" height="17"
                                                    style="vertical-align: top;" /></div>
                                            <div class="whatsapp-header-number">online</div>
                                        </div>
                                    </div>
                                </h5>
                            </div>
                            
                        </div>
                    </div>
                    <div class="chatbox-right-outer">
                        <div class="chatbox-inner-content" id="chatcontainer">
                            <div class="p-dialog-content">
                                <div class="conversation-container">
                                    <div class="row">
                                        <div class="col-lg-3 col-md-6 col-12">
                                            <div class="custom-form-group">
                                                <label class="form-label">Mobile No.<span
                                                        class="text-danger">*</span></label>
                                                <div class="input-group custom-input-group">
                                                    <div class="col-lg-3 col-md-3 col-3">
                                                        <Multiselect v-model="countrycode" :options="countryCodeList"
                                                            :searchable="true" label="label" placeholder="Select" disabled
                                                            class="multiselect-custom custom-title-multiselect"
                                                            :canClear="false" :closeOnSelect="true" :object="true"
                                                            noOptionsText="No Result" :canDeselect="false" style="font-family: Inter-Regular !important;
        font-weight: 400;" />
                                                    </div>
                                                    <div class="col-lg-9 col-md-9 col-9">
                                                        <input type="text" :disabled="disabledmobinput || sendotpbtnloader"
                                                            v-model="add.votermobile" class="form-control"
                                                            id="webChatMobileNoInput" placeholder="Enter Mobile No."
                                                            maxlength="10" @paste="onPasteMobile"
                                                            @keypress="onlyNumberMobile" autocomplete="off" />
                                                    </div>
                                                </div>
                                                <div class="custom-error" v-if="v$.add.votermobile.$error">
                                                    {{ v$.add.votermobile.$errors[0].$message }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-12 col-12 btn-marign-top" v-if="!disabledmobinput">
                                            <div class="custom-form-group mb-0">
                                                <button type="button" class="header-btn-outer btn btn-primary"
                                                    @click="sendMobileOtp()" :disabled="sendotpbtnloader">
                                                    <span v-if="!sendotpbtnloader">Submit</span>
                                                    <div class="
                                                        spinner-border
                                                        text-light
                                                        custom-spinner-loader-btn
                                                    " role="status" v-if="sendotpbtnloader">
                                                        <span class="visually-hidden">Loading...</span>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-6 col-12" v-if="otpShowFlag">
                                            <div class="custom-form-group">
                                                <label for="formaadharcardinput" class="form-label d-flex align-items-center justify-content-between"><span>Enter OTP <span
                                                        class="text-danger">*</span></span><span v-if="timerMobileStatus" class="otp-counter-text mt-1">{{
                                        timerMobileInterval
                                    }}</span></label>
                                                <input type="text" v-model="mobileotp" class="form-control otp-input-space"
                                                    id="formaadharcardinput" placeholder="_         _         _         _" autocomplete="off"
                                                    maxlength="4" minlength="4" @keypress="onlyNumber" @paste="onPasteOtp"
                                                    :disabled="showresentloader || verifybtnloader" />
                                                <div class="text-center">
                                                    <button @click="resendMobileOtp()"
                                                        class="btn btn-link aadhar-resent-otp-btn"
                                                        v-if="resentotpbtnstatus && this.resendbtndisabled != 2"
                                                        :disabled="showresentloader">
                                                        <span v-if="!showresentloader">Resend OTP</span>
                                                        <div class="spinner-border custom-spinner-icon-loader-btn"
                                                            role="status" v-if="showresentloader">
                                                            <span class="visually-hidden">Loading...</span>
                                                        </div>
                                                    </button>
                                                </div>
                                                <span class="custom-error" v-if="mobileotperr">
                                                    {{ mobileotperr }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-12 text-success d-lg-none d-xs-block d-sm-block d-md-block"
                                            v-if="successMsg">
                                            {{ this.successMsg }}
                                        </div>
                                        <div class="col-lg-3 col-md-12 col-12 btn-marign-top" v-if="otpShowFlag">
                                            <div class="custom-form-group mb-0">
                                                <button type="button" class="header-btn-outer btn btn-success"
                                                    @click="verifyMobileOtp()"
                                                    :disabled="mobileotp.length != 4 || verifybtnloader">
                                                    <span v-if="!verifybtnloader">Verify</span>
                                                    <div class="
                                                        spinner-border
                                                        text-light
                                                        custom-spinner-loader-btn
                                                    " role="status" v-if="verifybtnloader">
                                                        <span class="visually-hidden">Loading...</span>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 text-success d-lg-block d-none" v-if="successMsg">
                                            {{ this.successMsg}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="showloadermodal">
                <div class="custom-modal-spinner-loader">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { required, minLength, helpers } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import ApiService from "../service/ApiService";
export default {
    data() {
        return {
            v$: useValidate(),
            countrycode: { value: 101, label: "+91" },
            countryCodeList: [],
            add: {
                votermobile: "",
            },
            mobileotp: '',
            sendotpbtnloader: false,
            successMsg: '',
            disabledmobinput: false,
            timerMobileInterval: null,
            timerMobileCount: 120,
            timerMobileStatus: false,
            mobileotperr: '',
            otpShowFlag: false,
            showresentloader: false,
            resentotpbtnstatus: false,
            verifybtnloader: false,
            rowid: '',
            encryptedid: '',
            resendbtndisabled: '',
            whatsappname: "Government Of Goa",
            whatsappprofile: '',
            routeParam: '',
            client_info: '',
            clientname: '',
            clientlogo: '',
            bucket_name: '',
            logoname: '',
            showloadermodal: false,
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    validations() {
        return {
            add: {
                votermobile: { required: helpers.withMessage('Please enter Mobile No.', required), minLength: helpers.withMessage('Enter vaild Mobile No.', minLength(10)) },
            },
        };
    },
    mounted() {
        this.getclientdetails();
        if(this.$route.params.param1) {
            this.routeParam = this.$route.params.param1;
            if (localStorage.getItem("webbotid") !== null ) {
                if (localStorage.getItem("webbotid") == this.routeParam) {
                    localStorage.setItem("webbotid", this.routeParam);
                }else{
                    console.log('page not found');
                    this.$router.push("/webbot/"+localStorage.getItem("webbotid"));
                }
            }else{
                localStorage.setItem("webbotid", this.routeParam);
            }
        }
    },
    watch: {
        "add.votermobile"(mobileNumber) {
            this.add.votermobile = mobileNumber.replace(/[^0-9]/g, "")
        }
    },
    methods: {
        getclientdetails() {
            this.showloadermodal = true;
            this.ApiService.getclientdetails().then((items) => {
                if (items.success === true) {
                    this.showloader = false;
                    localStorage["webbot_info"] = JSON.stringify(
                        items.data
                    );
                    this.client_info = JSON.parse(localStorage.webbot_info);
                    this.clientname = this.client_info.client_info.maa65;
                    this.bucket_name = this.client_info.master_bucket_name;
                    this.logoname = this.client_info.client_info.maa6;
                    this.clientlogo = "https://storage.googleapis.com/" + this.bucket_name + "/Client/Logo/" + this.logoname;
                this.showloadermodal = false;
                }
            });
        },
        sendMobileOtp() {
            this.v$.add.$validate();
            let fields = {};
            fields["ak24"] = this.add.votermobile;
            if (!this.v$.add.$error) {
                this.sendotpbtnloader = true;
                this.ApiService.verifyMobileNumber(fields).then((data) => {
                    if (data.status == 200) {
                        this.sendotpbtnloader = false;
                        this.rowid = data.data.ak1;
                        this.resendbtndisabled = data.data.ak161;
                        this.disabledmobinput = true;
                        this.otpShowFlag = true;
                        this.startMobileTimer();
                        this.timerMobileStatus = true;
                        this.successMsg = data.message;
                        setTimeout(() => {
                            this.successMsg = '';
                        }, 5000);
                    } else {
                        this.sendotpbtnloader = false;
                        this.disabledmobinput = false;
                        this.otpShowFlag = true;
                        this.successMsg = '';
                    }
                });
            } else {
                this.$error;
            }
        },
        resendMobileOtp() {
            this.v$.add.$validate();
            this.mobileotperr = '';
            let fields = {};
            fields["ak24"] = this.add.votermobile;
            this.resendbtndisabled = this.resendbtndisabled + 1;
            fields["ak161"] = this.resendbtndisabled;
            fields["ak1"] = this.rowid;
            if (!this.v$.add.$error) {
                this.showresentloader = true;
                this.ApiService.verifyMobileNumber(fields).then((data) => {
                    if (data.status == 200) {
                        this.resentotpbtnstatus = false;
                        this.showresentloader = false;
                        this.rowid = data.data.ak1;
                        this.resendbtndisabled = data.data.ak161;
                        this.startMobileTimer();
                        this.timerMobileStatus = true;
                        this.successMsg = data.message;
                        setTimeout(() => {
                            this.successMsg = '';
                        }, 5000);
                    } else {
                        this.showresentloader = false;
                        this.successMsg = '';
                    }
                });
            } else {
                this.$error;
            }
        },
        startMobileTimer() {
            this.timerMobileStatus = true;
            if (this.timerMobileCount > 0) {
                setTimeout(() => {
                    this.timerMobileCount--;
                    this.startMobileTimer();
                }, 1000);
                const minutes = Math.floor(this.timerMobileCount / 60);
                let seconds = this.timerMobileCount % 60;
                if (seconds < 10) {
                    this.timerMobileInterval = `0${minutes}:0${seconds}`;
                } else {
                    this.timerMobileInterval = `0${minutes}:${seconds}`;
                }
            } else {
                this.timerMobileCount = 120;
                this.timerMobileStatus = false;
                this.mobileotp = '';
                this.resentotpbtnstatus = true;
            }
        },
        verifyMobileOtp() {
            let fields = {};
            fields["ak1"] = this.rowid;
            fields["otp"] = this.mobileotp;
            this.verifybtnloader = true;
            this.ApiService.verifyOtp(fields).then((data) => {
                if (data.status == 200) {
                    this.verifybtnloader = false;
                    // this.successMsg = data.message;
                    this.encryptedid = data.data;
                    this.redirectToChatBot();
                    this.resentotpbtnstatus = false;
                    this.timerMobileStatus = false;
                    this.timerMobileCount = 0;
                    // this.add.votermobile = '';
                    // this.mobileotp = '';
                    this.mobileotperr = '';
                    this.sendotpbtnloader = false;
                    this.disabledmobinput = false;
                    this.otpShowFlag = false;
                    this.showresentloader = false;
                    this.rowid = '';
                } else {
                    this.verifybtnloader = false;
                    this.mobileotperr = data.message;
                    this.mobileotp = '';
                    this.timerMobileStatus = false;
                    this.timerMobileCount = 0;
                    this.successMsg = '';
                }
            });
        },
        redirectToChatBot() {
            var url = window.location.toString();
            var chatbotUrl = url.replace('webbot/'+this.routeParam, `chatbot?value=${this.encryptedid}`);
            window.location = chatbotUrl;    
            // this.$router.push(`chatbot?value=${this.encryptedid}`);
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
        },
        onlyNumberMobile($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var mobileInput = document.getElementById("webChatMobileNoInput");
            if ((mobileInput.value == '' || mobileInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
                $event.preventDefault();
            }
        },
        onPasteOtp(event) {
            let otpvalue = event.clipboardData.getData('text');
            if (/^[0-9 ]+$/.test(otpvalue)) {
                let value = otpvalue.replace(/\s/g, '');
                this.mobileotp = value.slice(0, 4);
                return true;
            }
            else event.preventDefault();
        },
        onPasteMobile(event) {
            let mobvalue = event.clipboardData.getData('text');
            if (/^[0-9 ]+$/.test(mobvalue)) {
                let value = mobvalue.replace(/\s/g, '');
                this.add.votermobile = value.slice(0, 10);
                return true;
            }
            else event.preventDefault();
        },
    },
};
</script>
<style scoped>
.chatbox-right-outer .form-label{
    font-family: 'Inter-Regular';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #070829;
}
.custom-form-group .otp-input-space {
    letter-spacing: 20px;
    text-align: center;
    border: 0;
}

.custom-form-group .otp-input-space::placeholder {
    text-align: center;
}
.header-btn-outer {
    min-width: 100px;
    background: #4849A1;
}

@media (min-width: 320px) and (max-width: 1024px) {
    .header-btn-outer {
        width: 100%;
    }

    .btn-marign-top {
        margin-top: 0;
    }
}




.btn-marign-top {
    margin-top: 26px;
}


/* new chatbot css start here */
.chatbot-header-outer-padding {
    font-family: 'Inter-Regular';
    background-color: #ffffff;
    width: 100%;
}

.whatsapp-header {
    padding: 13px 20.5px;
    gap: 70px;
    border-radius: 12px;
    box-shadow: 0px 1px 5.6px 0px #00000026;
    background: #FFFFFF;
    height: 72px;
}

.whatsapp-header-name {
    font-family: 'Inter-Regular';
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
    color: #000000;
    margin-bottom: 6px;
    height: 21px;
    padding-top: 4px;
}

.whatsapp-header-number {
    font-family: 'Inter-Regular';
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    color: #C4C4D5;
    height: 15px;
}

.chatbot-header-inner-bg {
    padding: 21px 15px 19px 15px;
    background: linear-gradient(179.44deg, #FFFFFF 29.31%, rgba(255, 255, 255, 0.01) 89.07%);
}
.form-control::placeholder {
    color: #707070;
    opacity: 1;
    font-size: 12px;
    font-family: 'Inter-Regular';
    text-align: start;
    text-transform: capitalize;
    line-height: 14.52px;
}
.otp-counter-text {
    font-family: 'Inter-Regular';
    font-weight: 700;
    color: #4849A1;
}
.aadhar-resent-otp-btn {
    font-family: 'Inter-Regular';
    font-weight: 400;
    text-decoration: none;
    margin-top: 20px;
}
.conversation-container {
    height: calc(100% - 5px);
    padding: 0 25px;
}
/* #old css start here */

.chatbox-right-outer {
    width: 100%;
    max-width: 100%;
    opacity: 1;
    padding: 0;
    height: 100%;
    overflow: auto;
    font-family: 'Inter-Regular';
    font-size: 14px;
    font-weight: 400;
}
.header-btn-outer, .custom-form-group .form-control, .custom-form-group .custom-error, .custom-form-group .multiselect-dropdown {
    font-family: 'Inter-Regular';
    font-weight: 400;
}
.chatbox-right-outer .chatbox-inner-content {
    height: calc(100% - 0px);
    /* box-shadow: inset 0 10px 10px -10px #000000; */
    padding: 2px 0;
    overflow: auto;
    position: relative;
}
.p-dialog-content {
    padding: 0;
}

.whatsapp-header .modal-title {
    color: #ffffff;
}
.conversation-container::-webkit-scrollbar,
.p-dialog-content::-webkit-scrollbar,
.input-msg::-webkit-scrollbar {
    transition: all 0.5s !important;
    width: 3px !important;
    height: 3px !important;
    z-index: 10 !important;
}

.conversation-container::-webkit-scrollbar-thumb,
.p-dialog-content::-webkit-scrollbar-thumb,
.input-msg::-webkit-scrollbar-thumb {
    background: #c54d4d !important;
    /* background: #7f7777 !important; */
}
</style>